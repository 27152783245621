<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <DistibutorMenu></DistibutorMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
        <v-row class="rowBg" v-if="userDetails.isActive">
          <v-col
            cols="11"
            md="2"
            offset-md="2"
            class="orderStatBox"
             style="background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Awaiting Shipment <br/> ({{orders.length}})</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #DED84C 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Awaiting Delivery <br/> ({{OrderAwaitingDelivery.length}})</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #618BBD 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Deleted Orders <br/> ({{OrdersDeleted.length}})</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #B55620 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Completed Orders <br/> ({{OrdersCompleted.length}})</p>
          </v-col>
        </v-row>

        <!-- <v-row class="rowBg mt-10" v-if="userDetails.isActive">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="orderSearch.orderNumber"
              :rules="orderNumberRules"
              :counter="10"
              solo
              label="Order number"
              required
            ></v-text-field>
            <v-text-field
              v-model="orderSearch.sellerName"
              solo
              label="Seller Name"
              v-if="this.filters == true"
            ></v-text-field>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="orderSearch.date"
              transition="scale-transition"
              offset-y
              min-width="auto"
              v-if="this.filters == true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="orderSearch.date"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  solo
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="orderSearch.date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(orderSearch.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="orderSearch.product"
              solo
              label="Product"
            ></v-text-field>
            <v-text-field
              v-model="orderSearch.trackingNumber"
              solo
              label="Tracking Number"
              v-if="this.filters == true"
            ></v-text-field>
            <v-select
              :items="statusItems"
              v-model="orderSearch.Status"
              label="Status"
              solo
              v-if="this.filters == true"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <div align="center">
              <v-btn align="center" class="buttonStyle" large>
                Search
              </v-btn>
            </div>
            <div class="mt-3" align="center">
              <p style="color:#4EA8DB">
                More Filters
                <v-icon color="black" dark @click="toggleFilters"
                  >mdi-arrow-down-drop-circle</v-icon
                >
              </p>
            </div>
          </v-col>
        </v-row> -->

        <v-row class="rowBg mt-10" v-if="userDetails.isActive">
          <v-col cols="12">
            
            <v-simple-table fixed-header height="500px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Order Id
                          </th>
                          <th class="text-left">
                           Sub Total
                          </th>
                          <th class="text-left">
                            Status
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(order, index) in orders"
                          :key="index"
                        >
                          <td>
                            {{ order.orderId }}
                          </td>
                          <td>
                            ${{ order.subTotal }}
                          </td>
                          <td>
                            <span v-if="order.status == 'delivered'" style="color:blue">Delivered</span>
                            <span v-if="order.status == 'created'" style="color:red">Awaiting Dispatch</span>
                            <span v-if="order.status == 'approved'" style="color:green">Order dispatched awaiting confirmation</span>
                          </td>
                          <td>
                            <v-btn
                              small
                              v-if="order.status == 'created'"
                              @click="openOrderDialog(order)"
                              class="mr-3"
                              >View Order</v-btn
                            >

                            <v-btn
                              small
                              v-if="order.status == 'approved'"
                              @click="openOrderDialog(order)"
                              class="mr-3"
                              >Confirm Order</v-btn
                            >

                            <v-btn
                              small
                              v-if="order.status == 'delivered'"
                              @click="openOrderDialog(order)"
                              class="mr-3"
                              >Review order</v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row wrap v-else class="mb-10">
          <v-col cols="12" align="Center">
              <v-layout wrap>
                <v-col cols="12" align="Center">
                  <v-icon size="200" color="red">mdi-alert-circle</v-icon>
                </v-col>
              </v-layout>
              <h3>Account awaiting approval</h3>
              <v-layout wrap>
                <v-col cols="12" md="10" offset-md="1" align="Center">
                  <p>Hello <b>{{userDetails.name}}</b>! We are glad to have you sign up on qikPharma as a <b>Distributor</b>. Your account is currently under review and will be approved sooner than you can imagine.</p>
                </v-col>
              </v-layout>
             
          </v-col>
        </v-row>

        <v-dialog v-model="dialogOrder"  width="600" persistent>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                  <span>Order Item Details</span>
                    <v-spacer></v-spacer>
                  <v-btn
                                icon
                                dark
                                @click="dialogOrder = false"
                  >
                     <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-col cols="12">
                    <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <th>
                                  <td>Order Details</td>
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    Order Id
                                  </td>
                                  <td>
                                    {{ singleOrder.orderId }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Order Status
                                  </td>
                                  <td>
                                    
                                    <span v-if="singleOrder.status == 'delivered'" style="color:blue">Order delivered. Now awaiting your review</span>
                                    <span v-if="singleOrder.status == 'created'" style="color:red">Awaiting Dispatch</span>
                                    <span v-if="singleOrder.status == 'approved'" style="color:green">Order dispatched awaiting confirmation. Please click the confirm button below to confirm the order when the order has been delivered to you.</span>
                                    <br/>
                                    <br/>
                                    <v-btn small @click="approveOrder(singleOrder.orderId)" v-if="singleOrder.status == 'approved'">
                                      Confirm Order
                                    </v-btn>

                                    <v-btn small v-if="singleOrder.status == 'delivered'">
                                      Review
                                    </v-btn>

                                    <v-btn small class="ml-3" to="/en/distributor/all/products" v-if="singleOrder.status == 'delivered'">
                                      Add Product
                                    </v-btn>
                                      
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                   
                    <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <th>
                                  <td>Shipping Details</td>
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    Shipping Company
                                  </td>
                                  <td>
                                    {{ singleOrder.LogisticsCompany.name }}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    Contact Person
                                  </td>
                                  <td>
                                    {{ singleOrder.LogisticsCompany.contactPerson }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Phone Number
                                  </td>
                                  <td>
                                   
                                    <a :href="'tel:' + singleOrder.LogisticsCompany.phoneNumber" class="headerALink">
                                      <span
                                        ><v-icon>mdi-phone-message-outline</v-icon>  {{ singleOrder.LogisticsCompany.phoneNumber }}</span
                                      >
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Email
                                  </td>
                                  <td>
                                    {{ singleOrder.LogisticsCompany.email }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                    </v-simple-table>
                    <v-divider></v-divider>

                    <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <th>
                                  <td>Ordered Items</td>
                                </th>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(order, index) in singleOrder.OrderItems"
                                  :key="index"
                                >
                                  <td>
                                    <v-img
                                      :src="order.Product.productImagePath"
                                      alt="saved Product"
                                      width="80px"
                                      height="100px"
                                      contain
                                      style="border-radius:10px;"
                                    />
                                  </td>
                                  <td>
                                    <h6>{{ order.Product.name }}</h6>
                                    <span><b>Unit Price:</b> ${{ order.price }}</span> <br/>
                                    <span><b>Qty:</b> {{ order.qty }}</span> <br/>
                                    <span><b>Total:</b> ${{ order.total }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                    </v-simple-table>

                  </v-col>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DistibutorMenu from "../others/DistributorMenu.vue";

export default {
  name: "DistributorOrders",

  components: {
    DistibutorMenu,
  },

  data: () => ({
    dialogOrder:false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/distributor_dashboard/en/home",
      },
      {
        text: "My Orders",
        disabled: true,
        href: "/#/en/distributor/orders",
      },
    ],
    filters: false,
    menu: false,
    search: "",
    searchResult: [],
    orderSearch: {
      orderNumber: "",
      product: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISO,
    },
    orderNumberRules: [
      (v) => !!v || "Order number is required",
      (v) => v.length <= 10 || "Number must be less than 10 characters",
    ],
    statusItems: [
      "All Orders",
      "Awaiting Payment",
      "Awaiting Cancellation",
      "Awaiting Delivery",
      "Order Complete",
      "Frozen Orders",
      "Payment not confirmed",
    ],
    userDetails:{
      isActive:true,
    },
    singleOrder:{},
    orders:[],
    OrderAwaitingDelivery:[],
    OrdersDeleted:[],
    OrdersCompleted:[]
  }),

  created() {
    this.getUserDetails();
    this.fetchOrders();
  },
  methods: {
    getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    approveOrder(orderId){
        this.loader =true;
        
        this.$http
        .put(`${process.env.VUE_APP_URL}order/delivered/` + orderId)
        .then((response) => {
          console.log(response);
          this.loader =false;
          this.singleOrder = response.data.data;
          this.fetchOrders();
        });
    },
    fetchOrders() {
      console.log(localStorage.getItem("userId"));
        this.$http
        .get(
          `${process.env.VUE_APP_URL}order/` + localStorage.getItem("userId")
        )
        .then((response) => {
          console.log(response.data.data);
          this.orders = response.data.data;
          response.data.data.forEach(element => {
             if(element.status == 'awaiting-delivery'){
               this.OrderAwaitingDelivery.push(element);
             }

             if(element.status == 'deleted'){
               this.OrdersDeleted.push(element);
             }

             if(element.status == 'completed'){
               this.OrdersCompleted.push(element);
             }
          });
         
        });
    },
    openOrderDialog(order) {
      this.dialogOrder = true;
      this.singleOrder = order;
      console.log(order);
    },
    toggleFilters() {
      this.filters = !this.filters;
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.orderStatBox {
  padding-top: 30px !important;
  border-radius: 10px;
  text-align: center;
  margin: 15px;
}

.orderStatText {
  color: white;
  font-size: 16px;
  text-align: center;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.headerALink {
  text-decoration: none;
}

</style>
